/*globals jQuery, visible*/
(function ($) {
    'use strict';

    var $topicList = $('ul#title-banner__list'),
        $slick,
        currentLinkIndex,
        slideCount,
        currentLink,
        showRightSibling;
    
    if ($topicList.length) {

        $topicList
            .on('afterChange', function (event, slick, currentSlide) {

                // Hide $prevArrow when no LHS slides to show
                if (currentSlide === 0) {
                    slick.$prevArrow.hide().addClass('slick-disabled');
                } else {
                    slick.$prevArrow.show().removeClass('slick-disabled');
                }

                // Enable slide scroll when 1 or 2 elements remain hidden
                switch (currentSlide) {
                case 1:
                    $topicList.slick('slickSetOption', 'slidesToScroll', 1, false);
                    break;
                case 2:
                    $topicList.slick('slickSetOption', 'slidesToScroll', 2, false);
                    break;
                default:
                    $topicList.slick('slickSetOption', 'slidesToScroll', 3, false);
                }

                // Hide $nextArrow when last slide is visible
                if (visible(slick.$slides[slick.slideCount - 1])) {
                    slick.$nextArrow.hide().addClass('slick-disabled');
                } else {
                    slick.$nextArrow.show().removeClass('slick-disabled');
                }
            });

        // Find current link and store its index value
        currentLinkIndex = $topicList.find('.title-banner__link--active').parents('.title-banner__item').index();
        currentLink = currentLinkIndex !== -1 ? currentLinkIndex : 1;

        // DLP-14: show current page as second in the menu
        showRightSibling = (currentLink === 0) ? 0 : currentLink - 1;

        $topicList.slick({
            accessibility:  false,
            slidesToScroll: 3,
            initialSlide:   showRightSibling,
            focusOnSelect:  false,
            centerMode:     false,
            dots:           false,
            infinite:       false,
            variableWidth:  true
        });

        // After init
        $topicList.each(function () {
            $slick     = $(this).slick('getSlick');
            slideCount = $slick.slideCount;

            // Hide prev arrow if first slide
            // also manage slidesToScroll option in case 1st or 3rd
            switch ($slick.currentSlide) {
            case 0:
                $slick.$prevArrow.hide().addClass('slick-disabled');
                break;
            case 1:
                $topicList.slick('slickSetOption', 'slidesToScroll', 1, false);
                break;
            case 2:
                $topicList.slick('slickSetOption', 'slidesToScroll', 2, false);
                break;
            default:
                $topicList.slick('slickSetOption', 'slidesToScroll', 3, false);
            }

            // Hide $nextArrow when last slide is visible
            if (visible($slick.$slides[$slick.slideCount - 1])) {
                $slick.$nextArrow.hide().addClass('slick-disabled');
            } else {
                $slick.$nextArrow.show().removeClass('slick-disabled');
            }
        });
    }

     const titleBanerBorder = {
        setting: {
            titleBanerClass:         'title-banner',
            titleBannerHeadingClass: 'title-banner__heading-wrapper'
        },

        init: () => {
            const _this = titleBanerBorder;

            if (document.querySelector(`.${_this.setting.titleBanerClass}`)) {
                const titleBanner = document.querySelector(`.${_this.setting.titleBanerClass}`);

                if (!titleBanner.querySelector(`.${_this.setting.titleBannerHeadingClass}`)) {
                    titleBanner.setAttribute("style", "border-bottom: 0");
                }
            }
        }
    }

    titleBanerBorder.init();

    const backlinkModule = {
        settings: {
            backlinkWrapperClass: 'title-banner__backlink-wrapper',
            headingClass:         'title-banner__heading-wrapper',
            backLinkClass:        'title-banner__backlink'

        },

        init: () => {
            const _this = backlinkModule;

            if (document.querySelector(`.${_this.settings.backlinkWrapperClass}`)) {
                if (!document.querySelector(`.${_this.settings.headingClass}`)) {
                    _this.eventHandler();
                    _this.event(_this.eventHandler);

                }
            }
        },
        eventHandler: () => {
            const backlink = document.querySelector(`.${backlinkModule.settings.backLinkClass}`);

            if (viewport.getType() === 'global') {
                backlink.setAttribute("style", "margin: 1.25rem 0");
            } else {
                backlink.setAttribute("style", "margin: 1.5625rem 0");
            }
        },
        event: (handler) => {
            window.addEventListener('resize', handler);
        }
    }

    backlinkModule.init();
}(jQuery));