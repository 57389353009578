/*global jQuery, visible*/
var accordions = {};

(function ($) {
    'use strict';

    accordions.init = function (drawer) {
        var $accordion = (drawer) ? $('#content-drawer .accordion') : $('.accordion'),
            $tabs      = (drawer) ? $('#content-drawer .tabs') : $('.tabs'),
            $tabsList  = (drawer) ? $('#content-drawer .tabs__tab-list') : $('.tabs__tab-list'),
            $slick,
            slideCount;

        $accordion.UberAccordion({
            buttonClass:          'accordion__link',
            autoExpand:           null,
            toggle:               true,
            multiple:             true,
            expandOnFocus:        false,
            preventDefaultButton: true,
            hashChange:           true
        });

        $tabs.UberAccordion({
            buttonClass: 'tabs__link',

            autoExpand: function ($elem) {
                return window.location.hash || this.$buttons.first().attr('href');
            },

            toggle:               false,
            multiple:             false,
            preventDefaultButton: true
        });

        if ($tabs.length) {

            $tabsList
                .on('afterChange', function (event, slick, currentSlide) {

                    // Hide $prevArrow when no LHS slides to show
                    if (currentSlide === 0) {
                        slick.$prevArrow.hide();
                    } else {
                        slick.$prevArrow.show();
                    }

                    // Hide $nextArrow when last slide is visible
                    if (visible(slick.$slides[slick.slideCount - 1])) {
                        slick.$nextArrow.hide();
                    } else {
                        slick.$nextArrow.show();
                    }
                });

            if ($tabsList.length) {

                $tabsList.slick({
                    accessibility:  false,
                    slidesToScroll: 1,
                    dots:           false,
                    focusOnSelect:  false,
                    centerMode:     false,
                    infinite:       false,
                    touchMove:      true,
                    variableWidth:  true
                });

                $tabsList.each(function () {
                    $slick     = $(this).slick('getSlick');
                    slideCount = $slick.slideCount;

                    // Hide prev arrow on init
                    $slick.$prevArrow.hide();

                    // Hide $nextArrow when last slide is visible
                    if (visible($slick.$slides[$slick.slideCount - 1])) {
                        $slick.$nextArrow.hide();
                    } else {
                        $slick.$nextArrow.show();
                    }
                });
            }
        }
    };

    // Document ready
    $(document).ready(function () {

        // Run init
        accordions.init();

    });
}(jQuery));