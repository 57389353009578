/*global jQuery*/
(function ($) {
    'use strict';

    var $formInput        = $('form .sq-form-question input'),
        $emailInput       = $('form .sq-form-question-email-address input'),
        $formInputWrapper = $('form .sq-form-question'),
        focusClass       = 'sq-form-question--focus',
        customFileInputs;

    // Check for Matrix's required fields
    if ($formInputWrapper.length) {
        $formInputWrapper.each(function () {
            if ($(this).find('.sq-form-required-field').length) {
                $(this).find('input').addClass('sq-form-field--required');
            }
        });
    }

    // Check for email inputs
    if ($emailInput.length) {
        $emailInput.each(function () {
            $(this).addClass('sq-form-field--email');
        });
    }

    // Input's label show/hide
    $formInput
        .on('focus', function () {
            $(this).parents('.sq-form-question').addClass(focusClass);
        })
        .on('focusout', function () {
            if (!$(this).val().length) {
                $(this).parents('.sq-form-question').removeClass(focusClass);
            }
        });

    // jQuery class based validation rule
    $.validator.addClassRules('sq-form-field--required', {
        required: true
    });

    // Create custom error message for class based validation rule
    $.validator.addMethod('delwpEmail', $.validator.methods.email,
        $.validator.format('Oops, that email address in invalid'));

    $.validator.addClassRules('sq-form-field--email', {
        delwpEmail: true
    });

    // Validation
    $('form').each(function () {
        $(this).validate({
            errorClass:     'sq-form-error',
            errorElement:   'p',
            errorPlacement: function (error, element) {
                error.insertAfter(element.parents('.sq-form-question-answer'));
            },
            highlight: function (element, errorClass) {
                $(element).parents('.sq-form-question').addClass('sq-form-question-error');
            },
            unhighlight: function (element, errorClass) {
                $(element).parents('.sq-form-question').removeClass('sq-form-question-error');
            }
        });
    });

    /* ========================================================
     * Custom file input styles
     * ========================================================
     *
     * Applies a custom style to file input elements by hiding
     * the native element and injecting a styled façade element
     * above the original file input.
     * ======================================================== */
    customFileInputs = (function () {

        return {

            init: function () {

                var fileInputs,
                    placeholderText,
                    facade;

                // Original file input elements
                fileInputs = $('input[type="file"]');

                // Placeholder text that appears when empty input is initially loaded
                placeholderText = 'Attachment upload';

                // Façade overlay element that will have custom styles applied
                facade = '<span title="" class="sq-form-question-facade is-empty">' + placeholderText + '</span>';

                fileInputs.each(function (i, el) {
                    var fileInputID = el.id,
                        facadeOverlay;

                    // Generate empty façade element with custom styles applied
                    facadeOverlay = $(facade).insertAfter(el);

                    // Has ID - add aria markup
                    if (typeof fileInputID !== 'undefined' && fileInputID) {
                        facadeOverlay.attr('aria-controls', fileInputID);
                    }

                    // Façade element activates corresponding file input
                    facadeOverlay.on('touchstart click', function (ev) {
                        $(ev.currentTarget).prev('input[type="file"]').trigger('click');
                    });

                    // When value of selected file changes, print the value to the façade element
                    $(el).on('change', function (ev) {
                        var fileInput = $(ev.currentTarget),
                            filePath = fileInput.val(),
                            facade = fileInput.next('.sq-form-question-facade');

                        // File path is not empty
                        if (filePath.trim()) {
                            facade.removeClass('is-empty');
                            facade.text(filePath);
                        } else {
                            facade.addClass('is-empty');
                            facade.text(placeholderText);
                        }

                        // Apply title (to display full path and file name as tooltip)
                        facade.attr('title', fileInput.val());

                        // Grab focus to apply appropriate styles
                        fileInput.focus();

                    });

                });
            }

        };

    }());
    // Custom file input styling
    customFileInputs.init();

    // selectric
    $('select.sq-form-field').selectric();

}(jQuery));

